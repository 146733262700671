/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAppSettings = /* GraphQL */ `
  query GetAppSettings($id: ID!) {
    getAppSettings(id: $id) {
      id
      name
      key
      value
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAppSettings = /* GraphQL */ `
  query ListAppSettings(
    $filter: ModelAppSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        key
        value
        description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getComicPanels = /* GraphQL */ `
  query GetComicPanels($id: ID!) {
    getComicPanels(id: $id) {
      id
      name
      uri
      chapter
      page
      description
      text
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listComicPanels = /* GraphQL */ `
  query ListComicPanels(
    $filter: ModelComicPanelsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComicPanels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        uri
        chapter
        page
        description
        text
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCast = /* GraphQL */ `
  query GetCast($id: ID!) {
    getCast(id: $id) {
      id
      status
      name
      key
      backstory
      notes
      refPicture
      relationships
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCasts = /* GraphQL */ `
  query ListCasts(
    $filter: ModelCastFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCasts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        status
        name
        key
        backstory
        notes
        refPicture
        relationships
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCharacters = /* GraphQL */ `
  query GetCharacters($id: ID!) {
    getCharacters(id: $id) {
      id
      name
      key
      refPicture
      backstory
      notes
      stats
      spells
      feats
      inventory
      relationships
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCharacters = /* GraphQL */ `
  query ListCharacters(
    $filter: ModelCharactersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCharacters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        key
        refPicture
        backstory
        notes
        stats
        spells
        feats
        inventory
        relationships
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLorePoints = /* GraphQL */ `
  query GetLorePoints($id: ID!) {
    getLorePoints(id: $id) {
      id
      name
      key
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLorePoints = /* GraphQL */ `
  query ListLorePoints(
    $filter: ModelLorePointsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLorePoints(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        key
        description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNotes = /* GraphQL */ `
  query GetNotes($id: ID!) {
    getNotes(id: $id) {
      id
      name
      key
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listNotes = /* GraphQL */ `
  query ListNotes(
    $filter: ModelNotesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        key
        description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getQuests = /* GraphQL */ `
  query GetQuests($id: ID!) {
    getQuests(id: $id) {
      id
      name
      key
      status
      priority
      description
      background
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listQuests = /* GraphQL */ `
  query ListQuests(
    $filter: ModelQuestsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        key
        status
        priority
        description
        background
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getStoryPoints = /* GraphQL */ `
  query GetStoryPoints($id: ID!) {
    getStoryPoints(id: $id) {
      id
      name
      key
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listStoryPoints = /* GraphQL */ `
  query ListStoryPoints(
    $filter: ModelStoryPointsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStoryPoints(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        key
        description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWorldPoints = /* GraphQL */ `
  query GetWorldPoints($id: ID!) {
    getWorldPoints(id: $id) {
      id
      type
      name
      key
      description
      image
      coordinates
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listWorldPoints = /* GraphQL */ `
  query ListWorldPoints(
    $filter: ModelWorldPointsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorldPoints(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        name
        key
        description
        image
        coordinates
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
